import './HowBuy.scss'
import { Link } from 'gatsby'

import checkBlue from '../../../images/icons/CheckIconBlue.svg'

export default function HowBuy() {
  return (
    <section className="sectionHowBuy">
      <div className="containerHowBuyTitle">
        <h2>FAÇA O SEGURO VOCÊ MESMO!</h2>
        <h2>VEJA COMO É RÁPIDO E FÁCIL</h2>
      </div>
      <div className="containerHowBuySteps">
        <div className="containerHowBuyStep1">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/tri-1.png?v=637394270661830000"
            alt="Passo 1"
            height={120}
            width={60}
            className="imagemHowBuyStep"
          />
          <div className="containerText">
            <h2>Após comprar a sua bike na Decathlon:</h2>
            <span className="containerHowBuyText">
              <img src={checkBlue} alt="Check" />
              <Link
                to="https://app.clubesantuu.com.br/login?next=/new/simulation/bike-info/?key=2022071621321235%26program=decathlon"
                className="no-underline hover:text-secondary"
              >
                Acesse o Clube Santuu
              </Link>
            </span>
            <p>O Clube Santuu é a plataforma oficial do seguro B•ON.</p>
          </div>
        </div>
        <div className="containerHowBuyStep2">
          <img
            src="https://decathlonstore.vteximg.com.br/arquivos/tri-2.png?v=637394270650900000"
            alt="Passo 2"
            width={60}
            height={120}
            className="imagemHowBuyStep"
          />
          <div className="containerText">
            <h2>Dentro do Clube Santuu:</h2>
            <span className="containerHowBuyText">
              <img src={checkBlue} alt="Check" />
              Selecione o seu seguro B•ON
            </span>
            <span className="containerHowBuyText">
              <img src={checkBlue} alt="Check" />
              Forneça as fotos e os dados solicitados
            </span>
            <span className="containerHowBuyText">
              <img src={checkBlue} alt="Check" />
              Realize o pagamento
            </span>
            <p>Pronto! A sua bike está protegida!</p>
          </div>
        </div>
      </div>
    </section>
  )
}
