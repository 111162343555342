import SecureBon from 'src/components/Campanha/SeguroBon/Main'
import Seo from 'src/components/seo/Seo'

export default function Page() {
  return (
    <>
      <Seo title="Seguro BON | Decathlon" />
      <SecureBon />
    </>
  )
}
